<template>
    <b-col cols="6">
        <b-card
            border-variant="secondary"
            :title="title"
            bg-variant="transparent"
            class="shadow-none"
            >
            <b-card-text>
                <b-row class="text-center">
                    <b-col>
                        <h5>Harga</h5>
                        <p>{{ price }}</p>
                    </b-col>
                    <b-col>
                        <h5>Impression</h5>
                        <p>{{ impresi }}</p>
                    </b-col>
                </b-row>
            </b-card-text>
        </b-card>
    </b-col>
</template>

<script>
import {
    BCard,
    BCardText,
    BRow,
    BCol
} from 'bootstrap-vue'

export default {
    name: 'CardPost',
    props: [
        'title',
        'price',
        'impresi'
    ],
    components: {
        BCard,
        BCardText,
        BRow,
        BCol
    }
}
</script>
<template>
    <div>
        <b-row class="mb-1">
            <b-col>
                <feather-icon icon="YoutubeIcon" /> {{ insight.username }}
            </b-col>
        </b-row>
        <b-row class="mb-1">
            <b-col cols="1" class="text-center">
                <h6>Post</h6>
                <p class="text-center">{{ insight.post }}</p>
            </b-col>
            <b-col cols="2" class="text-center">
                <h6>Subscribers</h6>
                <p class="text-center">{{ insight.followers }}</p>
            </b-col>
            <b-col cols="2" class="text-center">
                <h6>Viewers</h6>
                <p class="text-center">{{ insight.following }}</p>
            </b-col>
            <b-col cols="2" class="text-center">
                <h6>Engagement</h6>
                <p class="text-center">{{ insight.engagement }}</p>
            </b-col>
        </b-row>
        <b-row class="w-100">
            <CardPost v-for="(card, key) in cards" :title="card.service.name" :price="card.price_cast" impresi="0" :key="key"/>
        </b-row>
    </div>
</template>

<script>
import {
    BContainer,
    BRow,
    BCol
} from 'bootstrap-vue'
import CardPost from './CardPost.vue'
import axios from '@axios'

export default {
    name: 'YoutubeInsight',
    props: [
        'insight'
    ],
    components: {
        BContainer,
        BRow,
        BCol,

        CardPost
    },
    data() {
        return {
            cards: null
        }
    },
    mounted() {
        let _ = this
        _.getPrice()
    },
    methods: {
        getPrice() {
            let _ = this
            axios.get('/misc/types/pricing/youtube?followers=' + _.insight.followers)
                .then(res => {
                    _.cards = res.data.data
                })
                .catch(err => {
                    console.log(err.message)
                })
        }
    }
}
</script>
<template>
    <b-card
        header-tag="header">
        <template #header>
            <div>
                <router-link :to="backDetail">Back</router-link>
            </div>
        </template>
        <b-row>
            <b-col cols="3" class="text-center">
                <b-avatar class="mb-1" :src="influencer.account.avatar" size="150px"></b-avatar>
            </b-col>
            <b-col cols="3">
                <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">Nama</h5>
                </div>
                <p class="mb-1">
                    {{ influencer.fullName }} <b-icon-check-circle-fill v-if="influencer.account.verified"></b-icon-check-circle-fill>
                </p>

                <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">Jenis Kelamin</h5>
                </div>
                <p class="mb-1">
                    {{ influencer.gender }}
                </p>

                <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">Usia</h5>
                </div>
                <p class="mb-1">
                    {{ influencer.age }}
                </p>
            </b-col>
            <b-col cols="3">
                <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">Lokasi</h5>
                </div>
                <p class="mb-1">
                    {{ influencer.city }}
                </p>

                <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">Interest</h5>
                </div>
                <p class="mb-1">
                    {{ influencer.contentTypes ? influencer.contentTypes.map(v => v.name).join(', ') : '' }}
                </p>
            </b-col>
            <b-col cols="3" style="padding-top: 180px">
                <!-- <b-button class="float-right">Lihat Review</b-button> -->
                <router-link :to="'/detail_influencer/'+influencer.id+'/review'"
                    tag="button"
                    class="btn btn-primary float-right"
                    >
                    Lihat Review
                </router-link>
            </b-col>
        </b-row>
        <hr>
        <b-row>
            <b-col cols="12">
                <b-tabs>
                    <b-tab active>
                        <template #title>
                            <h5>Instagram</h5>
                        </template>

                        <InstagramInsight :insight="instagramData" v-if="hasInstagram"/>
                        <p class="text-center" v-else-if="hasInstagram == null">
                            Please wait...
                        </p>
                        <p class="text-center" v-else>
                            Data Tidak Ditemukan
                        </p>
                    </b-tab>
                    <b-tab>
                        <template #title>
                            <h5>Facebook</h5>
                        </template>

                        <FacebookInsight :insight="facebookData" v-if="hasFacebook"/>
                        <p class="text-center" v-else-if="hasFacebook == null">
                            Please wait...
                        </p>
                        <p class="text-center" v-else>
                            Data Tidak Ditemukan
                        </p>
                    </b-tab>
                    <b-tab>
                        <template #title>
                            <h5>Youtube</h5>
                        </template>

                        <YoutubeInsight :insight="youtubeData" v-if="hasYoutube"/>
                        <p class="text-center" v-else-if="hasYoutube == null">
                            Please wait...
                        </p>
                        <p class="text-center" v-else>
                            Data Tidak Ditemukan
                        </p>
                    </b-tab>
                </b-tabs>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import {
    BCardGroup,
    BContainer,
    BCard,
    BCardBody,
    BCardText,
    BAvatar,
    BRow,
    BCol,
    BButton,
    BTabs,
    BTab,
    BIconCheckCircleFill
} from 'bootstrap-vue'
import InstagramInsight from './InstagramInsight.vue'
import FacebookInsight from './FacebookInsight.vue'
import YoutubeInsight from './YoutubeInsight.vue'

import axios from '@axios'

export default {
    name: 'DetailInfluencer',
    components: {
        BCardGroup,
        BContainer,
        BCard,
        BCardBody,
        BCardText,
        BAvatar,
        BRow,
        BCol,
        BButton,
        BTabs,
        BTab,
        BIconCheckCircleFill,

        InstagramInsight,
        FacebookInsight,
        YoutubeInsight
    },
    data() {
        return {
            influencerID: this.$route.params.id,
            influencer: {},
            hasInstagram: null,
            instagramData: null,
            hasFacebook: null,
            facebookData: null,
            hasYoutube: null,
            youtubeData: null
        }
    },
    mounted() {
        let _ = this
        _.getInfluencerDetail()
    },
    methods: {
        getInfluencerDetail() {
            axios.get('/advertiser/influencers/' + this.influencerID)
                .then(resp => {
                    let _ = this
                    _.influencer = resp.data.data
                    let socialMedia = _.influencer.socialMediaProfiles
                    socialMedia.forEach(sm => {
                        console.log(sm)
                        if ( sm.type == 'instagram' ) {
                            _.hasInstagram = true
                            _.instagramData = sm
                        } else if ( sm.type == 'facebook' ) {
                            _.hasFacebook = true
                            _.facebookData = sm
                        } else if ( sm.type == 'youtube' ) {
                            _.hasYoutube = true
                            _.youtubeData = sm
                        }
                    })
                    
                    _.hasInstagram = _.hasInstagram ? true:false
                    _.hasFacebook = _.hasFacebook ? true:false
                    _.hasYoutube = _.hasYoutube ? true:false
                })
                .catch(err => {
                    console.log(err)
                })
        }
    },
    computed: {
        backDetail() {
            let _ = this
            return _.$route.query.campaignId ? { name: 'campaign-list-applicant', params: { id: _.$route.query.campaignId } } : {name: 'find-influencer'}
        }
    }
}
</script>